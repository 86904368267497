import React from 'react';
import Logo from '../../static/img/logo.png'
import PartyPopper from '../../static/img/party-popper.png'

function ThankYouPage({ setIsUploadDone }) {
    return (
        <div className='w-full h-[100vh]'>
            <div className="container-lg h-full flex flex-col justify-around items-center">
                <div className='logo w-[32rem]'>
                    <img className='w-full h-full' src={Logo} alt='logo'/>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='w-[10rem] h-[10rem] p-[1rem]'>
                        <img className='w-full h-full' src={PartyPopper} alt='party-popper' />
                    </div>
                    <div className='flex flex-col justify-between items-start ml-[4rem]'>
                        <h2 className='text-[3.2rem] figtree-semibold'>Thank you.</h2>
                        <h2 className='text-[1.6rem] figtree-light mt-[2rem] border-b-[.1rem] border-[#ECF0EC] pb-[3rem] mb-[3rem]'>Your files have been successfully submitted. Your insurance broker will be in touch for next steps.</h2>
                    </div>
                </div>
                <button
                    className='w-[36rem] h-[5rem] mt-[4rem] uppercase figtree-bold text-[#fff] text-[1.6rem] bg-[#4DB444] hover:bg-[#4DB444]/90 hover:scale-95 rounded-[.5rem]'
                    onClick={() => setIsUploadDone(false)}
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default ThankYouPage;
