// msalConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "d4b347d5-a937-4215-a4ad-7cc9258fe328", //YOUR_CLIENT_ID
        authority: "https://login.microsoftonline.com/b2887c37-285f-4fdf-bd8e-9dc83b2d0c29", //YOUR_TENANT_ID
         redirectUri: "http://localhost:3001",
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set to true if you are having issues on IE11 or Edge
    }
};
const loginRequest = {
    scopes: ["Sites.ReadWrite.All"],
};


const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, loginRequest, msalConfig};
