import React, { useEffect, useState } from 'react';
import Logo from '../../static/img/logo.png';
import Times from '../../static/img/times.png';
import axios from "axios";
import toast from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";  // Import reCAPTCHA

function FileUploadPage({ setIsUploadDone }) {
    const [firmName, setFirmName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedFilesArr, setSelectedFilesArr] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploading, setUploading] = useState(false);
    const [firmError, setFirmError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [captchaVerified, setCaptchaVerified] = useState(false); // State for captcha
    const [dragging, setDragging] = useState(false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (e) => {
        setEmail(e.target.value.trim());
        setEmailError(emailRegex.test(e.target.value) ? '' : '* Invalid Email ');
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        const totalFiles = [...selectedFilesArr, ...newFiles];
        const uniqueFiles = totalFiles.filter((file, index, self) =>
            index === self.findIndex((f) => (
                f.name === file.name && f.size === file.size
            ))
        );
        if (uniqueFiles.length > 5) {
            toast.error('You can only upload a maximum of 5 files.');
            return;
        }
        setSelectedFilesArr(uniqueFiles);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const newFiles = Array.from(e.dataTransfer.files);
        const totalFiles = [...selectedFilesArr, ...newFiles];

        if (totalFiles.length > 5) {
            toast.error('You can only upload a maximum of 5 files.');
            return;
        }
        setSelectedFilesArr(totalFiles);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!dragging) {
            setDragging(true);
        }
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const removeFileByIndex = () => {
        if (fileToDelete !== null) {
            const updatedFiles = selectedFilesArr.filter((_, i) => i !== fileToDelete);
            setSelectedFilesArr(updatedFiles);
            setFileToDelete(null);
            setShowDeleteModal(false);
        }
    };

    const handleDeleteClick = (index) => {
        setFileToDelete(index);
        setShowDeleteModal(true);
    };

    const handleFileUpload = async () => {
        if (!captchaVerified) {
            toast.error("Please complete the CAPTCHA verification.");
            return;
        }

        if (selectedFilesArr.length === 0) {
            toast.error("Please select Files!");
            return;
        }
        if (!firmName) setFirmError('* firm required')
        else setFirmError('');
        if (!name) setNameError('* name required');
        else setNameError('');
        if (!email) setEmailError('* email required');
        if (email && emailRegex.test(email)) setEmailError('');
        if (!firmName || !name || !email || !emailRegex.test(email)) return;

        let url = 'https://rvtpilot.blob.core.windows.net/rvtpilotcontainer1/';
        url = url + firmName.replace(/ /g, '-') + "_";
        url = url + name.replace(/ /g, '-') + "_";
        url = url + email + "/";

        try {
            setUploading(true);
            const uploadPromises = selectedFilesArr.map((file, index) => {
                let url2 = url + file.name;
                url2 = url2 + '?sv=2022-11-02&ss=bf&srt=o&sp=wactfx&se=2026-07-26T15:52:41Z&st=2024-07-26T07:52:41Z&spr=https&sig=oX%2B5V2u9kZOYeLvKb0FVlL1Lo35VW4Xud04Xntlq1v4%3D';

                const formData = new FormData();
                formData.append('file', file);

                return axios.put(url2, formData, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': 'application/xml',
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [index]: progress,
                        }));
                    },
                });
            });

            await Promise.all(uploadPromises);
            toast.success('File uploaded successfully.');

            // Reset all states
            setFirmName('');
            setName('');
            setEmail('');
            setSelectedFilesArr([]);
            setUploadProgress({});
            setUploading(false);
            setIsUploadDone(true);
            setCaptchaVerified(false); // Reset captcha state
        } catch (error) {
            toast.error('File upload failed.');
            console.error(error);
            setUploading(false);
        }
    };

    const handleCaptchaChange = (value) => {
        console.log("value :", value);

        setCaptchaVerified(!!value); // Set to true if value is not null
    };

    const handleCaptchaError = () => {
        console.log('There was an error with reCAPTCHA. Please try again.');
    };

    useEffect(() => {
        setCaptchaVerified(false)
    }, []);

    return (
        <div className='w-full p-[4rem]'>
            <div className="container-lg flex flex-col justify-center items-center">
                <div className='logo w-[32rem]'>
                    <img className='w-full h-full' src={Logo} alt='logo' />
                </div>
                <h2 className='text-[3.6rem] figtree-semibold mt-[4rem]'>Welcome to Revit upload screen</h2>
                <h2 className='text-[1.6rem] figtree-light mt-[1rem] mb-[4rem]'>You are heading towards a fairer insurance future!</h2>
                <div className='flex justify-between items-start w-full'>
                    <div className='w-[30%]'>
                        <div className="relative mt-2 w-full overflow-x-visible">
                            <input
                                type="text"
                                id="firmName"
                                className={`border-[.1rem] peer block z-10 w-full appearance-none rounded-lg border-[#ECF0EC] bg-transparent px-[1.6rem] py-[1rem] text-[1.6rem] text-[#364135] focus:border-blue-600 focus:outline-none focus:ring-0 ${firmError && 'border-red-600 outline-none'}`}
                                placeholder="Enter firm name "
                                value={firmName || " "}
                                onChange={(e) => { setFirmName(e.target.value) }}
                                autoFocus
                            />
                            <label
                                htmlFor="firmName"
                                className={`absolute top-0 left-[2rem] z-15 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white text-[1.6rem] text-[#757875] duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 ${firmError && 'text-red-600'}`}
                            >
                                Firm Name
                            </label>
                        </div>
                        {firmError && <p className='text-red-600 w-full flex justify-end text-[1.2rem]'>{firmError}</p>}
                    </div>
                    <div className='w-[30%]'>
                        <div className="relative mt-2 w-full overflow-x-visible">
                            <input
                                type="text"
                                id="name"
                                className={`border-[.1rem] peer block z-10 w-full appearance-none rounded-lg border-[#ECF0EC] bg-transparent px-[1.6rem] py-[1rem] text-[1.6rem] text-[#364135] focus:border-blue-600 focus:outline-none focus:ring-0 ${nameError && 'border-red-600 outline-none'}`}
                                placeholder="Enter your name "
                                value={name || " "}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                            <label
                                htmlFor="name"
                                className={`absolute top-0 left-[2rem] z-15 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white text-[1.6rem] text-[#757875] duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 ${nameError && 'text-red-600'}`}
                            >
                                Your Name
                            </label>
                        </div>
                        {nameError && <p className='text-red-600 w-full flex justify-end text-[1.2rem]'>{nameError}</p>}
                    </div>
                    <div className='w-[30%]'>
                        <div className="relative mt-2 w-full overflow-x-visible">
                            <input
                                type="text"
                                id="email"
                                className={`border-[.1rem] peer block z-10 w-full appearance-none rounded-lg border-[#ECF0EC] bg-transparent px-[1.6rem] py-[1rem] text-[1.6rem] text-[#364135] focus:border-blue-600 focus:outline-none focus:ring-0 ${emailError && 'border-red-600 outline-none'}`}
                                placeholder="example@test.com "
                                value={email || " "}
                                onChange={(e) => { handleEmailChange(e) }}
                            />
                            <label
                                htmlFor="email"
                                className={`absolute top-0 left-[2rem] z-15 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white text-[1.6rem] text-[#757875] duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:scale-100 peer-focus:top-0 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 ${emailError && 'text-red-600'}`}
                            >
                                Your Email
                            </label>
                        </div>
                        {emailError && <p className='text-red-600 w-full flex justify-end text-[1.2rem]'>{emailError}</p>}
                    </div>
                </div>
                <h2 className='lora-semibold text-[2rem] text-[#364135] mt-[4rem] mb-[2rem]'>Select Files to Upload</h2>
                <h2 className='figtree-regular text-[1.6rem] text-[#364135]'>Upload Revit files of your top projects (upto 5 files) from last 3 years for analysis.</h2>
                <div className='w-full flex justify-center items-start h-[40rem] min-h-[40rem] my-[4rem]'>
                    <div className='w-[50%] h-full pr-[4rem]'>
                        <div className="flex items-center justify-center w-full h-full">
                            <label htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-full border-[.1rem] border-[#A0B39F] border-dashed rounded-lg cursor-pointer bg-[#F8F9FA] hover:bg-[#e8e9FA]">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6 text-[#757875] figtree-regular" onDragOver={handleDragOver} onDrop={handleDrop} onDragLeave={handleDragLeave}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="6.9rem" height="6.1rem" viewBox="0 0 69 61" fill="none">
                                        <path d="M36.285 15.4635C35.8977 15.348 35.6771 14.9408 35.7926 14.5535C37.138 10.0325 41.3282 6.71295 46.3139 6.71295C46.7179 6.71295 47.0457 7.04066 47.0457 7.44469C47.0457 7.84871 46.7179 8.17643 46.3139 8.17643C42.1353 8.17643 38.3856 10.9701 37.195 14.9705C37.0805 15.3573 36.6739 15.5794 36.285 15.4634L36.285 15.4635Z" fill="#4DB444" />
                                        <path d="M56.5588 43.2998H52.1685C51.7645 43.2998 51.4367 42.9721 51.4367 42.5681C51.4367 42.1641 51.7645 41.8363 52.1685 41.8363H56.5588C62.6107 41.8363 67.5348 36.9123 67.5348 30.8604C67.5348 24.8085 62.6107 19.8844 56.5588 19.8844H56.4532C56.241 19.8844 56.0393 19.7924 55.9002 19.632C55.7612 19.4715 55.6985 19.2588 55.7288 19.0487C55.7942 18.5929 55.8271 18.1351 55.8271 17.6893C55.8271 12.4443 51.5595 8.1767 46.3145 8.1767C44.274 8.1767 42.3282 8.81435 40.6869 10.0212C40.3263 10.2862 39.8141 10.1686 39.6055 9.77185C34.9575 0.921088 22.8173 -0.267479 16.5233 7.43192C13.8718 10.6756 12.8301 14.895 13.6649 19.0074C13.7568 19.4616 13.4093 19.885 12.9478 19.885H12.6545C6.60265 19.885 1.67859 24.8091 1.67859 30.8609C1.67859 36.9128 6.60265 41.8369 12.6545 41.8369H17.0449C17.4489 41.8369 17.7766 42.1646 17.7766 42.5686C17.7766 42.9726 17.4489 43.3004 17.0449 43.3004H12.6545C5.79556 43.3004 0.215057 37.7199 0.215057 30.8609C0.215057 24.1943 5.48661 18.7356 12.0801 18.4345C11.4608 14.168 12.6451 9.86434 15.3901 6.50576C22.1288 -1.73821 35.043 -0.81417 40.5022 8.37846C42.2438 7.28661 44.2366 6.71383 46.3143 6.71383C52.6688 6.71383 57.7042 12.1224 57.2636 18.4413C63.7964 18.8077 68.998 24.2377 68.998 30.8604C68.998 37.7199 63.4175 43.2998 56.5585 43.2998L56.5588 43.2998Z" fill="#4DB444" />
                                        <path d="M34.5085 60.4483C24.4216 60.4483 16.2151 52.242 16.2151 42.1549C16.2151 32.0679 24.4214 23.8614 34.5085 23.8614C44.5956 23.8614 52.802 32.0678 52.802 42.1549C52.802 52.2418 44.5956 60.4483 34.5085 60.4483ZM34.5085 25.3252C25.2287 25.3252 17.6789 32.8751 17.6789 42.1549C17.6789 51.4347 25.2288 58.9845 34.5085 58.9845C43.7883 58.9845 51.3382 51.4346 51.3382 42.1549C51.3382 32.875 43.7883 25.3252 34.5085 25.3252Z" fill="#4DB444" />
                                        <path d="M34.8406 50.0567C34.5448 50.0567 34.3049 49.8168 34.3049 49.521V35.5924C34.3049 35.2966 34.5448 35.0567 34.8406 35.0567C35.1364 35.0567 35.3763 35.2966 35.3763 35.5924V49.521C35.3763 49.8172 35.1364 50.0567 34.8406 50.0567Z" fill="#4DB444" />
                                        <path d="M39.1269 40.4139C38.9899 40.4139 38.8525 40.3614 38.7481 40.257L34.8412 36.3501L30.9344 40.257C30.7254 40.4663 30.386 40.4663 30.1767 40.257C29.9674 40.0477 29.9674 39.7086 30.1767 39.4993L34.4624 35.2137C34.6713 35.0044 35.0107 35.0044 35.22 35.2137L39.5057 39.4993C39.715 39.7086 39.715 40.0477 39.5057 40.257C39.4012 40.3618 39.2638 40.4139 39.1268 40.4139H39.1269Z" fill="#4DB444" />
                                    </svg>
                                    <p className="mt-[1rem] text-[1.4rem]">
                                        Below 1GB, RVT, RFA, RTE, RFT, DGN, DWF, DWG files
                                    </p>
                                    <p className="mb-[1rem] text-[1.4rem]">
                                        only
                                    </p>
                                    <p className="text-[1.6rem] text-[#364135]">
                                        Drag & drop files or <span className="text-[#4DB444] underline">Browse</span>
                                    </p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" accept=".rvt, .rfa, .rte, .rft, .dgn, .dwf, .dwg" multiple onChange={(e) => { handleFileChange(e); }} />
                            </label>
                        </div>
                    </div>
                    {selectedFilesArr?.length > 0 ?
                        <div className='w-[50%] h-full flex flex-col justify-between'>
                            <div className='flex flex-col'>
                            <h2 className='mulish-bold text-[1.4rem] text-[#676767]'>Uploading - {selectedFilesArr?.length} files</h2>
                            {
                                selectedFilesArr?.map((file, i) => {
                                    return (
                                        <div key={i} className={`${uploadProgress[i] === 100 ? 'flex flex-col justify-between items-start border-[.05rem] border-[#E3E3E3] rounded-[.4rem] mt-[1rem]' : 'flex flex-col justify-between items-start border-[.05rem] border-[#E3E3E3] rounded-[.4rem] mt-[1rem]'}`}>
                                            <div className='flex justify-between items-center px-[1.6rem] py-[1rem] w-full'>
                                                <h3 className='mulish-regular text-[1.2rem]'>{file.name}</h3>
                                                <div className='w-[2rem] h-[2rem] rounded-full p-[.2rem] cursor-pointer hover:scale-105' onClick={() => { handleDeleteClick(i) }}>
                                                    <img className='w-full h-full' src={Times} alt='delete-icon' />
                                                </div>
                                            </div>
                                            <div style={{ width: `${uploadProgress[i] || 0}%` }} className='h-[.2rem] rounded-full p-[.3rem] bg-[#483EA8] rounded-l-[1rem]' />
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <div className='flex flex-col justify-between items-center'>
                                {!captchaVerified ?
                                    <ReCAPTCHA
                                        sitekey="6LdYhxsqAAAAAHTBXNXiscyL6FDp9h1VhjEa5SES" // Replace with your site key
                                        onChange={handleCaptchaChange}
                                        className="mb-4"
                                    />
                                    :
                            <button
                                className='w-[24rem] h-[5rem] mt-auto mx-auto uppercase figtree-bold text-[#fff] text-[1.6rem] bg-[#4DB444] hover:bg-[#4DB444]/90 hover:scale-95 rounded-[.5rem]'
                                onClick={handleFileUpload} disabled={uploading}
                            >
                                Submit
                            </button>
                                }
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            {showDeleteModal && (
                <div id="deleteModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-modal md:h-full bg-black bg-opacity-50 text-[2rem]">
                    <div className="relative p-[4rem] w-full max-w-[40rem] h-full md:h-auto">
                        <div className="relative p-[4rem] text-center bg-white rounded-lg shadow dark:bg-gray-800">
                            <button type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-[1.6rem] p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setShowDeleteModal(false)}>
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <svg className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to remove this item?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button type="button" className="py-2 px-3 text-[1.6rem] font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600" onClick={() => setShowDeleteModal(false)}>
                                    No, cancel
                                </button>
                                <button type="button" className="py-2 px-3 text-[1.6rem] font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900" onClick={removeFileByIndex}>
                                    Yes, I'm sure
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            )}
            <div className="w-full mt-[4rem] flex justify-center">
                <a href="https://policyprecise.com/Privacy/"  target="_blank" className="text-[1.6rem] text-green-400 underline">
                    Privacy Policy
                </a>
                </div>
        </div>
    );
}

export default FileUploadPage;