// src/App.js
import React, {useEffect, useState} from "react";
import FileUploadPage from "./hoc/FileUploadPage/FileUploadPage";
import FileUpload from "./components/common/FileUpload/FileUpload";
import { msalInstance, loginRequest } from "./components/utils/authConfig/authConfig";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import './App.css';
import ThankYouPage from "./hoc/ThankYouPage/ThankYouPage";
import { Toaster } from "react-hot-toast";

const App = () => {
    const { instance } = useMsal();
    const [isUploadDone, setIsUploadDone] = useState(false)

    const handleLogin = () => {
        instance.loginPopup(loginRequest).catch((e) => {
            console.error(e);
        });
    };

    useEffect(() => {
        // Automatically login when the component mounts
        handleLogin();
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    useEffect(() => {
        msalInstance.initialize().catch(error => {
            console.error("MSAL initialization failed:", error);
        });
    }, []);

    return (
        <div className="App">
            <Toaster/>
            {/*<button onClick={handleLogin}>login</button>*/}
            {!isUploadDone ?
                <FileUploadPage setIsUploadDone={setIsUploadDone}/>
                :
                <ThankYouPage setIsUploadDone={setIsUploadDone}/>
            }
            {/*<MsalProvider instance={msalInstance}>*/}
            {/*    <AuthenticatedTemplate>*/}
            {/*        <FileUpload />*/}
            {/*    </AuthenticatedTemplate>*/}
            {/*    <UnauthenticatedTemplate>*/}
            {/*        <p>Please log in to upload files.</p>*/}
            {/*    </UnauthenticatedTemplate>*/}
            {/*</MsalProvider>*/}
        </div>
    );
};

export default App;
